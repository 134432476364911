import { CollectType, SettlementsType, IEarlyRepaymentFrontAsset } from '@dltru/dfa-models'
import { Alert, Box, Button, Divider, Price, BankCollectCommission } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { moneySelector } from '@store/money/selectors'

import styles from './RepaymentModal.m.less'
import { AccountLimitBlock } from '../AccountLimitBlock'

interface IRepaymentFormProps {
    fee: number | null
    application: IEarlyRepaymentFrontAsset
    onOpenAccountRefillInfo: (value: boolean) => void
    onCancel: () => void
    onOk: () => void
}

export const RepaymentForm: FC<IRepaymentFormProps> = ({
    fee,
    application,
    onCancel,
    onOpenAccountRefillInfo,
    onOk,
}) => {
    const account = useSelector(moneySelector.selectAccount)
    // const user_id = useSelector(authSelector.selectUserUid)

    const isDirect = application.asset.repayment_settlements_type === SettlementsType.direct
    const repaymentSum =
        (application.asset.redeem_price_per_dfa ?? 0) * (application.request.amount ?? 0)

    const [isBalanceError, setIsBalanceError] = useState(false)
    const [fee017, setFee017] = useState(0)
    const [repaymentWithCommissionSum, setRepaymentWithCommissionSum] = useState(0)

    /*
    useEffect(() => {
        if (dfa.repayment_collect_type === CollectType.bank_account) {
            const getFee017 = async () => {
                const paymentFee = await getCalculateFeeHelper({
                    user_id,
                    service_code: ServiceCodeFee.paymentOrder,
                    operation_amount: repaymentSum * 100,
                })

                setFee017(paymentFee.item.FeeAmount)
            }

            getFee017()
        }
    }, [dfa.repayment_collect_type])
    */

    useEffect(() => {
        const _fee = (fee ?? 0) + (fee017 ?? 0)
        const _repaymentWithCommissionSum = repaymentSum * 100 + _fee
        setRepaymentWithCommissionSum(_repaymentWithCommissionSum / 100)

        const sum = isDirect ? fee ?? 0 : _repaymentWithCommissionSum

        const balanceError = sum > (account?.balance?.available as number)
        setIsBalanceError(balanceError)
    }, [repaymentSum, fee, account, fee017])

    return (
        <>
            <div className={styles.initRepaymentModal}>
                <Box direction="column">
                    <p>Вы действительно хотите исполнить заявку на досрочное погашение ЦФА?</p>

                    <Divider margin={[10, 0]} />
                    <label className={styles.rowLabel}>
                        Сумма погашения{' '}
                        {isDirect ||
                        application.asset.repayment_collect_type === CollectType.bank_account
                            ? '*'
                            : ''}
                    </label>
                    <Price price={repaymentSum} />

                    <Divider margin={[10, 0]} />
                    <label className={styles.rowLabel}>Комиссия за погашение ЦФА</label>
                    <Price price={(fee || 0) / 100} />

                    <Divider margin={[10, 0]} />
                    <label className={styles.rowLabel}>Итого</label>
                    <Price price={repaymentWithCommissionSum} />

                    {application.asset.repayment_collect_type === CollectType.bank_account && (
                        <Box margin={[24, 0, 0, 0]}>
                            <BankCollectCommission feePaymentOrder={fee017 || 0} />
                        </Box>
                    )}

                    {isDirect && (
                        <Box margin={[0, 0, 0, 0]}>
                            <Divider margin={[24, 0]} />
                            <p className={styles.footnote}>
                                Сумму погашения необходимо перечислить Владельцу вне Платформы.
                            </p>
                        </Box>
                    )}

                    <Box>
                        <p className={styles.amountDescription}></p>
                    </Box>

                    <AccountLimitBlock
                        balance={account?.balance?.available}
                        account={String(account?.number)}
                        error={isBalanceError}
                        onClick={() => onOpenAccountRefillInfo(true)}
                    />

                    {!isDirect && (
                        <Box margin={[0, 0, 24, 0]}>
                            <Alert
                                showIcon
                                type="info"
                                description="Сумма погашения и комиссия за погашение ЦФА будут зарезервированы на вашем Лицевом счете и спишутся после внесения записи о погашении ЦФА в распределенный реестр."
                            />
                        </Box>
                    )}
                    {isDirect && (
                        <Box margin={[0, 0, 24, 0]}>
                            <Alert
                                showIcon
                                type="info"
                                description="Комиссия за погашение ЦФА будет зарезервирована на вашем Лицевом счете и спишется после внесения записи о погашении ЦФА в распределенный реестр."
                            />
                        </Box>
                    )}
                </Box>
            </div>
            <div className={styles.repaymentModalFooter}>
                <Button borderRadius={12} onClick={onCancel}>
                    Отмена
                </Button>
                <Button borderRadius={12} type="primary" onClick={onOk} disabled={isBalanceError}>
                    Да, погасить ЦФА
                </Button>
            </div>
        </>
    )
}
