import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import dayjs from 'dayjs'

import { TAgreementData } from '@dltru/dfa-models'
import { AuthForm, Box, Button, Form } from '@dltru/dfa-ui'

import { logout, sendAgreements } from '@store/auth'
import { authSelector } from '@store/auth/selectors'

import { InfoModal } from '@pages/Login/components/AgreementsForm/InfoModal'

import { Agreements, agreements } from '@components/Agreements'

interface IAgreemntsForm {
    oisAgreement: number
    personalDataAgreement: number
    riskAgreement: number
    userAgreement: number
    privacyAgreement: number
}

const AgreementsMap = {
    oisAgreement: 'rules_agreement',
    personalDataAgreement: 'personal_data_agreement',
    riskAgreement: 'risk_agreement',
    userAgreement: 'user_agreement',
    privacyAgreement: 'confidentiality_agreement',
} as Record<string, keyof TAgreementData>

export const AgreementsForm: FC = () => {
    const reduxDispatch = useDispatch()
    const [form] = Form.useForm<IAgreemntsForm>()

    const isFirstTimeLogin = useSelector(authSelector.selectIsFirstTimeLogin)

    const [checkedAgreements, setCheckedAgreements] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)

    const handleSendAgreements = () => {
        if (!checkedAgreements) {
            return
        }

        const callback = () => {
            setIsModalVisible(true)
            location.reload()
        }

        const agreementDate = dayjs().unix()
        const values = form.getFieldsValue()

        const data = Object.keys(values).reduce((acc, curr) => {
            const key = AgreementsMap[curr]
            acc[key] = agreementDate
            return acc
        }, {} as unknown as Partial<TAgreementData>)

        reduxDispatch(
            sendAgreements({
                data,
                callback,
            }),
        )
    }
    const handleLogout = () => {
        reduxDispatch(logout())
    }

    const onValuesChange = (_, allValues: Record<string, boolean>) => {
        const state = Object.entries(allValues)
            .filter((element) => Object.values(agreements).includes(element[0]))
            .reduce((currentValue, element) => currentValue && element[1], true)

        setCheckedAgreements(state)
    }

    return (
        <AuthForm
            title={
                isFirstTimeLogin ? 'Соглашения пользователя' : 'Изменение соглашений пользователя'
            }
            form={form}
            style={{ maxWidth: '440px' }}
            onFinish={handleSendAgreements}
            onValuesChange={onValuesChange}
        >
            <p>
                {isFirstTimeLogin
                    ? 'Ознакомьтесь и дайте свое согласие.'
                    : 'Ознакомьтесь и дайте свое согласие с новыми редакциями документов.'}
            </p>
            <Agreements />
            <Box margin={[16, 0, 0, 0]} direction="row" justify="space-between">
                <Button onClick={handleLogout} borderRadius={16} size="large">
                    Выйти
                </Button>
                <Button
                    disabled={!checkedAgreements}
                    borderRadius={16}
                    type="primary"
                    // loading={isLoading}
                    htmlType="submit"
                    size="large"
                >
                    Продолжить
                </Button>
            </Box>
            <InfoModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        </AuthForm>
    )
}
