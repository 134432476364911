// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".initRepaymentModal_spBdc {\n  display: flex;\n  flex-direction: column;\n  padding: 0 40px;\n}\n.repaymentModalFooter_XFQmG {\n  display: flex;\n  justify-content: flex-end;\n}\n.repaymentModalFooter_XFQmG button:first-child {\n  margin-right: 8px;\n}\n.rowLabel_oD060 {\n  color: #40434d;\n  font-family: SF Pro Display;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n}\n.footnote_v2JE9 {\n  position: relative;\n  padding-left: 24px;\n  font-size: 12px;\n  line-height: 20px;\n  color: #555861;\n}\n.footnote_v2JE9::before {\n  content: '*';\n  position: absolute;\n  left: 12px;\n  top: 0;\n  font-size: 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/EarlyRepayment/RepaymentModal/RepaymentModal.m.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,eAAA;AADJ;AAIA;EACI,aAAA;EACA,yBAAA;AAFJ;AAAA;EAKQ,iBAAA;AAFR;AAMA;EACI,cAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAJJ;AAOA;EACI,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AALJ;AAMI;EACI,YAAA;EACA,kBAAA;EACA,UAAA;EACA,MAAA;EACA,eAAA;AAJR","sourcesContent":["@import 'style/palette';\n\n.initRepaymentModal {\n    display: flex;\n    flex-direction: column;\n    padding: 0 40px;\n}\n\n.repaymentModalFooter {\n    display: flex;\n    justify-content: flex-end;\n\n    button:first-child {\n        margin-right: 8px;\n    }\n}\n\n.rowLabel {\n    color: #40434d;\n    font-family: SF Pro Display;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 22px;\n}\n\n.footnote {\n    position: relative;\n    padding-left: 24px;\n    font-size: 12px;\n    line-height: 20px;\n    color: @gray-8;\n    &::before {\n        content: '*';\n        position: absolute;\n        left: 12px;\n        top: 0;\n        font-size: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"initRepaymentModal": "initRepaymentModal_spBdc",
	"repaymentModalFooter": "repaymentModalFooter_XFQmG",
	"rowLabel": "rowLabel_oD060",
	"footnote": "footnote_v2JE9"
};
export default ___CSS_LOADER_EXPORT___;
