import { IDfaFront } from '@dltru/dfa-models'
import { Box, Button, CaseAdd, DEFAULT_PER_PAGE, PageHeader, Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { dropDfaDetails } from '@store/dfa/details'
import { dropDfaList, loadDfas } from '@store/dfa/list'
import { dfaListSelectors } from '@store/dfa/list/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import { gridConfig } from '@pages/MyEmissions/gridConfig'

import '../styles/style.less'

const statuses = ['collection_started', 'emitment_success']

const MyEmissions: FC = () => {
    const reduxDispatch = useDispatch()
    const { items, paging } = useSelector(dfaListSelectors.selectList)
    const isLoading = useSelector(dfaListSelectors.selectIsLoading)
    const isEmitter = useSelector(profileSelector.isEmitter)
    const navigate = useNavigate()
    const isEmissionDisabled = useSelector(profileSelector.isPartlyBlocked)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(loadDfas({ limit, status: statuses }))
    }

    const onNext = () => {
        reduxDispatch(loadDfas({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(loadDfas({ cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(loadDfas({ status: statuses, limit: DEFAULT_PER_PAGE }))
        reduxDispatch(dropDfaDetails())
        return () => {
            reduxDispatch(dropDfaList())
        }
    }, [])

    const onRow = (record: IDfaFront) => ({
        onClick: () => {
            navigate(`/offers/${record.id}`)
        },
    })

    const buttons = isEmitter
        ? [
              <Button
                  onClick={() => navigate('/issue-decision')}
                  color="default"
                  icon={<CaseAdd />}
                  borderRadius={12}
                  key="Новый выпуск"
                  disabled={isEmissionDisabled}
                  tooltipOnDisable="Действие недоступно. Ваш профиль частично заблокирован"
              >
                  Новый выпуск
              </Button>,
          ]
        : undefined

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Выпуски ЦФА" extra={buttons} />
            </Box>

            <Box padding={[0, 24, 24, 24]} className="tablebox">
                <Table
                    isLoading={isLoading}
                    columns={gridConfig}
                    dataSource={items ?? []}
                    cursors={paging?.cursors}
                    onPerPage={onPerPageChange}
                    onNext={onNext}
                    onPrev={onPrev}
                    onRow={onRow}
                    clickableRow
                    rowKey="id"
                />
            </Box>
        </>
    )
}

export default MyEmissions
