import { getTagProps } from '@resources/dfaState'
import dayjs from 'dayjs'

import { IDfaFront, getNoun } from '@dltru/dfa-models'
import { ColumnsType, IssueDateCell, LinkCell, Tag, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'


export const gridConfig: ColumnsType<IDfaFront> = [
    {
        title: 'Тикер',
        dataIndex: 'ticker_symbol',
        key: 'ticker_symbol',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/offers/${record.id}`),
    },
    {
        title: 'Наименование ЦФА',
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        ellipsis: true,
    },
    /*{
      title: 'Эмитент',
      dataIndex: 'emitter_full_name',
      key: 'emitter_full_name',
      sorter: true,
      ellipsis: true,
      render: (value, record) => LinkCell(value, `/clients/${record.emitter_id}`),
  },*/
    {
        title: 'Дата выпуска',
        dataIndex: 'issue_date',
        key: 'issue_date',
        sorter: true,
        ellipsis: true,
        render: IssueDateCell,
    },
    {
        title: 'Дата погашения',
        dataIndex: 'redeem_date',
        key: 'redeem_date',
        sorter: true,
        ellipsis: true,
        render: (value) => dayjs(value).format(VIEW_DATE_FORMAT),
    },
    {
        title: 'Кол-во выпущенных ЦФА',
        dataIndex: 'max_supply_invested',
        key: 'max_supply_invested',
        sorter: true,
        ellipsis: true,
    },
    {
        title: 'Цена, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        sorter: true,
        ellipsis: true,
    },
    {
        title: 'Цена погашения, ₽',
        dataIndex: 'redeem_price_per_dfa',
        key: 'redeem_price_per_dfa',
        sorter: true,
        ellipsis: true,
    },

    {
        title: 'Досрочное погашение',
        dataIndex: 'total_early_repayment_amount',
        key: 'total_early_repayment_amount',
        ellipsis: true,
        render: (value, record) => {
            if (!value) {
                return null
            }

            const color = record.new_early_repayment_amount ? 'red' : 'default'
            return (
                <Tag color={color}>{`${value} ${getNoun(
                    value,
                    'заявка',
                    'заявки',
                    'заявок',
                )} `}</Tag>
            )
        },
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        ellipsis: true,
        render: (value) => {
            const tagProps = getTagProps(value)
            return <Tag color={tagProps.color}>{tagProps.title}</Tag>
        },
    },
]