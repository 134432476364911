import { IEarlyRepaymentFrontAsset } from '@dltru/dfa-models'
import { Modal, QuestionCircle, Space } from '@dltru/dfa-ui'
import { FC, useCallback, useState } from 'react'

import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { RepaymentForm } from './RepaymentForm'
import { AccountRefillInfo } from '../AccountRefillInfo'

export interface IInitRepaymentModal {
    fee?: number | null
    application: IEarlyRepaymentFrontAsset
    onCancel: () => void
    onOk: () => void
}

export const RepaymentModal: FC<IInitRepaymentModal> = ({ fee, application, onCancel, onOk }) => {
    const [isAccountRefillModal, setIsAccountRefillModal] = useState(false)

    const onOkHandler = useCallback(() => {
        onOk()
    }, [])

    return (
        <>
            <Modal
                visible={true}
                onCancel={onCancel}
                width={600}
                closable={false}
                title={
                    <Space size={0}>
                        <QuestionCircle className="modal-icon modal-question-icon" />
                        Погасить ЦФА досрочно
                    </Space>
                }
            >
                <TransitionGroup className="slide-animation__block">
                    {!isAccountRefillModal ? (
                        <CSSTransition timeout={300} classNames="slide-animation">
                            <RepaymentForm
                                fee={fee}
                                application={application}
                                onCancel={onCancel}
                                onOpenAccountRefillInfo={setIsAccountRefillModal}
                                onOk={onOkHandler}
                            />
                        </CSSTransition>
                    ) : (
                        <CSSTransition timeout={300} classNames="slide-animation">
                            <AccountRefillInfo onClose={setIsAccountRefillModal} />
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </Modal>
        </>
    )
}
