import { Box, Button, InfoCircleOutlined, MiniModal, Space } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (state: boolean) => void
}

export const InfoModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const navigate = useNavigate()

    const onOk = () => {
        setIsModalVisible(false)
        navigate('/offers')
    }

    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            width={423}
            title={
                <Space size={0}>
                    <InfoCircleOutlined className="modal-icon modal-info-icon" />
                    Мы настраиваем Вашу учетную запись.
                </Space>
            }
            footer={[
                <Button onClick={onOk} borderRadius={12} type="primary">
                    Ок
                </Button>,
            ]}
        >
            <Box margin={[24, 0, 16, 40]}>
                Это может занять несколько минут. В это время некоторые функции системы будут
                недоступны.
            </Box>
        </MiniModal>
    )
}
