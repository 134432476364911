import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'
import { CertificateType } from '@dltru/dfa-models'
import { Box, Button, MiniModal, QuestionCircle } from '@dltru/dfa-ui'
import { FC, useCallback, useState } from 'react'

interface IComponentProps {
    onOk: (skid: string) => void
    onSignOk: (skid: string) => void
    onCancel: () => void
}

export const SendModal: FC<IComponentProps> = ({ onOk, onCancel, onSignOk }) => {
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)

    const onSubscribe = useCallback((cert: CertificateType) => {
        onOk(cert.skid)
    }, [])

    const onSign = useCallback((cert: CertificateType) => {
        onSignOk(cert.skid)
    }, [])

    return (
        <>
            <MiniModal
                visible={true}
                onCancel={onCancel}
                width={423}
                footer={[
                    <Button borderRadius={12} onClick={onCancel}>
                        Отмена
                    </Button>,
                    <Button
                        borderRadius={12}
                        type="primary"
                        onClick={() => setIsVisibleCertificateModal(true)}
                    >
                        Да, направить
                    </Button>,
                ]}
            >
                <div>
                    <Box direction="row" align="center">
                        <Box className="modal-content-shift">
                            <QuestionCircle className="modal-icon modal-question-icon" />
                        </Box>
                        <div className="ant-modal-title">
                            Отправить заявку на досрочное погашение?
                        </div>
                    </Box>
                    <Box margin={[8, 40, 0, 40]}>
                        Вы действительно хотите отправить заявку на досрочное погашение эмитенту?
                    </Box>
                </div>
            </MiniModal>
            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSubscribe}
                onSign={onSign}
            />
        </>
    )
}
